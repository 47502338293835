<template>
  <div class="tombola">
    <div :id="uid" />
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Engine,
  Render,
  Body,
  World,
  Runner,
  Bodies,
  Composite,
  Mouse,
  MouseConstraint,
} from 'matter-js'
// eslint-disable-next-line import/no-extraneous-dependencies
// import decomp from 'poly-decomp'

// Common.setDecomp(decomp)

export default {
  name: 'Tombola',
  setup() {
    const uid = `tombola-${Math.random().toString(36).slice(3)}`

    // Inicializar el motor de física
    const engine = Engine.create()
    const { world } = engine

    const canvasWidth = 500
    const canvasHeight = 500
    const centerX = canvasWidth / 2
    const centerY = canvasHeight / 2
    const balls = []
    const maximumBalls = 120
    const collisionDefault = 0x0001

    const compositeWalls = Composite.create()

    // create balls
    function generateBalls() {
      let initSprite = 1
      const maxSprite = 28
      for (let i = 0; i < maximumBalls; i++) {
        ++initSprite
        if (initSprite > maxSprite) initSprite = 1

        const posY = initSprite % 2 === 0 ? centerY / 2 : centerY
        const ball = Bodies.circle(centerX, posY, 14, {
          friction: 0,
          frictionAir: 0.0001,
          inertia: Infinity,
          restitution: 0,
          collisionFilter: {
            mask: collisionDefault,
          },
          render: {
            sprite: {
              texture: `/balls/ball-${initSprite}.png`,
              xScale: 0.135,
              yScale: 0.135,
            },
          },
        })
        balls.push(ball)
      }
    }

    function createTombola() {
      // create renderer
      const render = Render.create({
        element: document.getElementById(uid),
        engine,
        options: {
          width: canvasWidth,
          height: canvasHeight,
          showAngleIndicator: false,
          wireframes: false,
        },
      })

      Render.run(render)

      const runner = Runner.create()
      Runner.run(runner, engine)

      // create balls
      generateBalls()

      const weight = 15
      const sizeTrapezoid = 10
      const half = weight / 4
      // const sizePoint = weight * 1
      const divisor = 3.5
      const deg45 = Math.PI / 4
      const options = {
        isStatic: true,
        frictionAir: 0,
        friction: 0,
        collisionFilter: {
          category: collisionDefault,
        },
        render: {
          visible: false,
          fillStyle: '#f5d259',
        },
      }

      const walls = [
        // walls
        Bodies.rectangle(centerX, half, canvasWidth, weight, options), // top
        Bodies.rectangle(centerX, canvasHeight - half, canvasWidth, weight, options), // bottom
        Bodies.rectangle(canvasWidth - half, centerY, weight, canvasHeight, options), // right
        Bodies.rectangle(half, centerY, weight, canvasHeight, options), // left
        // Bodies.rectangle(centerX, centerY, sizePoint, sizePoint, { ...options, angle: deg45 }), // center

        // angles
        Bodies.rectangle(centerX / divisor, centerY / divisor, canvasWidth / 2, weight, { ...options, angle: -deg45 }), // top left
        Bodies.rectangle(canvasWidth - centerX / divisor, centerY / divisor, canvasWidth / 2, weight, { ...options, angle: deg45 }), // top right
        Bodies.rectangle(centerX / divisor, canvasHeight - centerY / divisor, canvasWidth / 2, weight, { ...options, angle: deg45 }), // bottom left
        Bodies.rectangle(canvasWidth - centerX / divisor, canvasHeight - centerY / divisor, canvasWidth / 2, weight, { ...options, angle: -deg45 }), // bottom right

        // points
        Bodies.trapezoid(centerX, sizeTrapezoid + half, 30, 40, 1, { ...options, angle: Math.PI }), // point top
        Bodies.trapezoid((canvasWidth - centerX / divisor) - sizeTrapezoid + half, (canvasHeight - centerY / divisor) - sizeTrapezoid, 30, 35, 1, { ...options, angle: -deg45 }), // point bottom right
        Bodies.trapezoid(centerX / divisor + sizeTrapezoid, (canvasHeight - centerY / divisor) - sizeTrapezoid + half, 30, 35, 1, { ...options, angle: deg45 }), // point bottom left
      ]

      // Crear un compuesto con todos los elementos
      Composite.add(compositeWalls, walls)

      // Agregar el compuesto al mundo
      World.add(world, compositeWalls)

      Composite.add(world, balls)

      // // add mouse control
      // const mouse = Mouse.create(render.canvas)
      // const mouseConstraint = MouseConstraint.create(engine, {
      //   mouse,
      //   constraint: {
      //     stiffness: 0.2,
      //     render: {
      //       visible: false,
      //     },
      //   },
      // })
      // Composite.add(world, mouseConstraint)
      // // keep the mouse in sync with rendering
      // render.mouse = mouse

      // Render.run(render)

      Render.lookAt(render, {
        min: {
          x: 0,
          y: 0,
        },
        max: {
          x: canvasWidth,
          y: canvasHeight,
        },
      })

      // const runner = Runner.create()
      // Runner.run(runner, engine)
    }

    const isPlay = ref(null)

    function stop() {
      balls.forEach(ball => {
        Body.set(ball, { restitution: 0 })
      })

      clearInterval(isPlay.value)
      isPlay.value = null
    }

    function play() {
      if (isPlay.value) {
        stop()
        return
      }

      balls.forEach(ball => {
        Body.set(ball, { restitution: 1 })
      })

      isPlay.value = setInterval(() => {
        Composite.rotate(compositeWalls, 0.06, { x: centerX, y: centerY })
      }, 1000 / 60)
    }

    function restoreBalls() {
      console.log('restoreBalls')
      Composite.remove(world, balls)
      balls.value = []
      generateBalls()
      Composite.add(world, balls)

      const runner = Runner.create()
      Runner.run(runner, engine)
    }

    onMounted(() => {
      createTombola()
    })

    return {
      uid,
      isPlay,
      play,
      stop,
      restoreBalls,
      // selectWinningNumbers,
    }
  },
}
</script>

<style lang="scss">
.tombola {
  width: 100%;
  max-width: 540px;
  position: relative;
  pointer-events: none;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url('/tombola/esfera.svg');
    z-index: 1;
    opacity: 0.3;
    pointer-events: none;
  }

  canvas {
    background-color: #222 !important;
    border-radius: 50%;
    overflow: hidden;
  }

  // > canvas {
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   z-index: 1;
  // }
}
</style>
