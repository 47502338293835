export const ballColors = [
  'ball-green',
  'ball-orange',
  'ball-yellow',
  'ball-red',
  'ball-blue',
]

export function getBallColor(index) {
  const mod = index % ballColors.length
  return ballColors[mod] ?? ballColors[0]
}
