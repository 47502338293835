<template>
  <b-container fluid>
    <b-navbar
      class="flex-row-reverse flex-md-row bg-transparent py-1"
      toggleable="md"
    >
      <b-navbar-toggle
        target="nav-collapse"
        class="d-flex d-md-none align-items-center"
      >
        <template #default="{ expanded }">
          <feather-icon v-if="expanded" icon="XIcon" size="24" />
          <feather-icon v-else icon="MenuIcon" size="24" />
        </template>
      </b-navbar-toggle>

      <b-navbar-brand>
        <div class="brand-logo">
          <vuexy-logo />
          <h2 class="brand-text text-primary ml-1">Estelarbet</h2>
        </div>
      </b-navbar-brand>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="w-100">
          <div
            v-if="!obfuscate"
            class="d-flex justify-content-center gap-1 w-100 flex-wrap flex-md-nowrap my-2 my-md-0"
          >
            <!-- <v-select
              v-model="type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="typeList"
              :clearable="false"
              placeholder="Campaña"
            /> -->
            <v-select
              v-model="lottery"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="lotteryList"
              :clearable="false"
              placeholder="Sorteo"
              @input="selectRaffle"
            />
          </div>

          <!-- <b-button v-if="!obfuscate" variant="primary" class="flex-0 text-nowrap" @click="downloadRaffle">
            <span class="mr-1">Descargar sorteo</span>
            <feather-icon icon="DownloadIcon" size="14" />
          </b-button> -->
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <b-row class="align-items-md-center mt-4 mb-2 mt-md-5 mb-md-3 px-lg-3">
      <b-col cols="12" md="4" class="order-1 order-md-0">
        <!-- <p class="m-md-0">Campaña: {{ type ? type.label : '' }}</p> -->
      </b-col>
      <b-col cols="12" md="4" class="order-0">
        <h1 class="text-center mb-2 mb-md-0">{{ raffleDetail ? raffleDetail.title : '' }}</h1>
      </b-col>
      <b-col cols="12" md="4" class="order-1 order-md-0">
        <p class="text-md-right m-md-0">{{ dateNow }}</p>
      </b-col>
    </b-row>

    <section class="main-section">
      <b-row>
        <b-col cols="12">
          <b-card class="card-tombola text-center rounded" no-body>
            <template #header>
              <div ref="listBallsRef" class="d-flex overflow-x-auto">
                <!-- <div v-for="(winner, index) in winnersList" :key="index" class="ball-add animate">
                  <div :class="['ball', getBallColor(index)]"><span class="ball-spot">{{ winner.id }}</span></div>
                </div> -->
              </div>
            </template>

            <b-card-body class="d-flex align-items-center justify-content-center flex-wrap">
              <tombola ref="tombolaRef" />
            </b-card-body>

            <template #footer>
              <div v-if="!obfuscate" class="d-flex flex-column align-items-center gap-1 w-50 mx-auto">
                <b-button
                  v-show="tombolaRef && !tombolaRef.isPlay"
                  variant="primary"
                  class="py-1 px-5"
                  :disabled="tombolaRef && !!tombolaRef.isPlay"
                  block
                  @click="play"
                >
                  Jugar
                </b-button>

                <b-button
                  v-show="tombolaRef && tombolaRef.isPlay"
                  variant="primary"
                  class="px-5"
                  block
                  @click="stop"
                >
                  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-50" style="transform: rotate(10deg);">
                    <g clip-path="url(#clip0_204_484)">
                      <path d="M8.00004 10C5.7909 10 4.00004 8.20914 4.00004 6V2.2963C4.00004 2.0204 4.00004 1.88245 4.04025 1.77199C4.10764 1.58681 4.25352 1.44094 4.43869 1.37354C4.54916 1.33333 4.68711 1.33333 4.963 1.33333H11.0371C11.313 1.33333 11.4509 1.33333 11.5614 1.37354C11.7466 1.44094 11.8924 1.58681 11.9598 1.77199C12 1.88245 12 2.0204 12 2.2963V6C12 8.20914 10.2092 10 8.00004 10ZM8.00004 10V12M12 2.66667H13.6667C13.9773 2.66667 14.1326 2.66667 14.2552 2.71741C14.4185 2.78508 14.5483 2.91486 14.616 3.07821C14.6667 3.20073 14.6667 3.35604 14.6667 3.66667V4C14.6667 4.61998 14.6667 4.92997 14.5986 5.18431C14.4136 5.87449 13.8745 6.41358 13.1843 6.59852C12.93 6.66667 12.62 6.66667 12 6.66667M4.00004 2.66667H2.33337C2.02275 2.66667 1.86743 2.66667 1.74492 2.71741C1.58157 2.78508 1.45178 2.91486 1.38412 3.07821C1.33337 3.20073 1.33337 3.35604 1.33337 3.66667V4C1.33337 4.61998 1.33337 4.92997 1.40152 5.18431C1.58646 5.87449 2.12555 6.41358 2.81574 6.59852C3.07007 6.66667 3.38006 6.66667 4.00004 6.66667M4.963 14.6667H11.0371C11.2007 14.6667 11.3334 14.534 11.3334 14.3704C11.3334 13.0613 10.2721 12 8.963 12H7.03708C5.72796 12 4.66671 13.0613 4.66671 14.3704C4.66671 14.534 4.79936 14.6667 4.963 14.6667Z" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_204_484">
                        <rect width="16" height="16" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>

                  Obtener Ganador
                </b-button>

                <b-button :variant="`${!!resultPlay ? 'primary' : 'outline-secondary'}`" :size="!!resultPlay ? 'lg' : 'sm'" class="px-5" block>Cerrar Sorteo</b-button>
              </div>
            </template>
          </b-card>
        </b-col>

        <b-col cols="12">
          <b-card :class="{ 'text-center rounded p-2': true, 'no-substitute': raffleDetail && !raffleDetail.required_substitutes }" no-body>
            <h4 class="mb-2">Ganadores</h4>

            <b-table
              :items="winnersList"
              :fields="tableColumns"
              class="table-container"
              responsive
            >
              <!-- Column: Position Award -->
              <template #cell(position)="data">
                <div class="d-flex align-items-center gap-1">
                  <b-avatar
                    v-if="data.item.awards.length === 1"
                    :id="`${data.item.id}-avatar`"
                    v-b-tooltip.hover
                    :src="awards[data.item.awards[0]].image"
                    :title="awards[data.item.awards[0]].name"
                    class="bg-transparent"
                    square
                  />
                  <template v-else>
                    <b-avatar
                      :id="`${data.item.id}-popover`"
                      :src="awards[data.item.awards[0]].image"
                      v-bind="{
                        badge: data.item.awards.length > 1 ? `+${data.item.awards.length - 1}` : false,
                        'badge-top': data.item.awards.length > 1,
                      }"
                      class="bg-transparent"
                      square
                    />
                    <b-popover
                      v-if="data.item.awards.length > 1"
                      :target="`${data.item.id}-popover`"
                      triggers="hover"
                    >
                      <div class="d-flex flex-wrap gap-1">
                        <b-avatar
                          v-for="(item, index) in data.item.awards" :key="index"
                          v-b-tooltip.hover
                          :title="awards[item].name"
                          :src="awards[item].image"
                          class="bg-transparent"
                          square
                        />
                      </div>
                    </b-popover>
                  </template>

                  <span>{{ dicPositions[data.item.position] ? `${dicPositions[data.item.position]} lugar` : data.item.position }}</span>
                </div>
              </template>

              <!-- Column: External ID -->
              <template #cell(external_id)="data">
                <span style="letter-spacing: 1px;">
                  {{ transformText(data.item.external_id) }}
                </span>
              </template>

              <!-- Column: Phone -->
              <template #cell(phone)="data">
                <span style="letter-spacing: 1px;">
                  {{ transformText(data.item.phone) }}
                </span>
              </template>

              <!-- Column: Email -->
              <template #cell(email)="data">
                <span style="letter-spacing: 1px;">
                  {{ transformText(data.item.email) }}
                </span>
              </template>

              <!-- Column: Status -->
              <template #cell(status)="data">
                <span
                  :class="{
                    'text-success': data.item.status === 'winner',
                    'text-danger': data.item.status === 'expelled',
                  }"
                >
                  {{ dicStatus[data.item.status] }}
                </span>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown
                  :id="`row-${data.item.id}-button`"
                  text=""
                  variant="outline-secondary"
                  toggle-class="px-1"
                  right
                >
                  <b-dropdown-item href="#" @click="() => otherForPosition(data.index + 1)">
                    <b-img :src="expelIcon" class="mr-50" />
                    Pedir otro
                  </b-dropdown-item>
                  <b-dropdown-item href="#" @click="() => togglePosition(data.index + 1)">
                    <b-img :src="substituteIcon" class="mr-50" />
                    Cambiar a Suplente
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-card>

          <b-card v-if="raffleDetail && raffleDetail.required_substitutes" class="text-center rounded p-2 mb-2" no-body>
            <h4 class="mb-2">Suplentes</h4>

            <b-table
              :items="substitutesList"
              :fields="tableColumns"
              class="table-container"
              responsive
            >
              <!-- Column: Position Award -->
              <template #cell(position)="data">
                <div class="d-flex align-items-center gap-1">
                  <b-avatar
                    v-if="data.item.awards.length === 1"
                    :id="`${data.item.id}-avatar`"
                    v-b-tooltip.hover
                    :src="awards[data.item.awards[0]].image"
                    :title="awards[data.item.awards[0]].name"
                    class="bg-transparent"
                    square
                  />
                  <template v-else>
                    <b-avatar
                      :id="`${data.item.id}-popover`"
                      :src="awards[data.item.awards[0]].image"
                      v-bind="{
                        badge: data.item.awards.length > 1 ? `+${data.item.awards.length - 1}` : false,
                        'badge-top': data.item.awards.length > 1,
                      }"
                      class="bg-transparent"
                      square
                    />
                    <b-popover
                      v-if="data.item.awards.length > 1"
                      :target="`${data.item.id}-popover`"
                      triggers="hover"
                    >
                      <div class="d-flex flex-wrap gap-1">
                        <b-avatar
                          v-for="(item, index) in data.item.awards" :key="index"
                          v-b-tooltip.hover
                          :title="awards[item].name"
                          :src="awards[item].image"
                          class="bg-transparent"
                          square
                        />
                      </div>
                    </b-popover>
                  </template>

                  <span>{{ dicPositions[data.item.position] ? `${dicPositions[data.item.position]} lugar` : data.item.position }}</span>
                </div>
              </template>

              <!-- Column: External ID -->
              <template #cell(external_id)="data">
                <span style="letter-spacing: 1px;">
                  {{ transformText(data.item.external_id) }}
                </span>
              </template>

              <!-- Column: Phone -->
              <template #cell(phone)="data">
                <span style="letter-spacing: 1px;">
                  {{ transformText(data.item.phone) }}
                </span>
              </template>

              <!-- Column: Email -->
              <template #cell(email)="data">
                <span style="letter-spacing: 1px;">
                  {{ transformText(data.item.email) }}
                </span>
              </template>

              <!-- Column: Status -->
              <template #cell(status)="data">
                <span
                  :class="{
                    'text-success': data.item.status === 'winner',
                    'text-danger': data.item.status === 'expelled',
                  }"
                >
                  {{ dicStatus[data.item.status] }}
                </span>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown
                  :id="`row-${data.item.id}-button`"
                  text=""
                  variant="outline-secondary"
                  toggle-class="px-1"
                  right
                >
                  <b-dropdown-item href="#" @click="() => otherForPosition(data.index + 1, 'substitute')">
                    <b-img :src="expelIcon" class="mr-50" />
                    Pedir otro
                  </b-dropdown-item>
                  <b-dropdown-item href="#" @click="() => togglePosition(data.index + 1)">
                    <b-img :src="winnerIcon" class="mr-50" />
                    Cambiar a Ganador
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </section>

    <raffle-winner-image
      ref="winnerRef"
      :visible="showModalWinner"
      :id="currentWinner && currentWinner.winner ? currentWinner.winner.id : ''"
      :label="currentWinner && currentWinner.winner ? currentWinner.winner.name : ''"
      :obfuscate="obfuscate"
      @onClose="closeAndSetWinner"
      @onWater="alAgua"
    />

    <div style="position: fixed;bottom: 5%;right: 90px;z-index: 999999;">
      <b-button @click="obfuscate = !obfuscate" variant="primary" size="sm" class="ml-3">Ofuscar</b-button>
    </div>
  </b-container>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable global-require */
import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BNavbar,
  BNavbarBrand,
  BNavbarToggle,
  BNavbarNav,
  BCollapse,
  BButton,
  BTable,
  BAvatar,
  BPopover,
  BDropdown,
  BDropdownItem,
  BImg,
  VBTooltip,
} from 'bootstrap-vue'
import { onMounted, ref, watch } from '@vue/composition-api'
import jwtConfig from '@/jwtConfig'
import { toast } from '@/libs/toastification-generic'
import router from '@/router'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@/@core/utils/utils'
import { getBallColor } from '@/@core/utils/ballColor'
import vSelect from 'vue-select'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import Tombola from '@/views/lottery/components/Tombola.vue'
import RaffleWinnerImage from '@/views/lottery/components/RaffleWinnerImage.vue'
import LotteryRequest from '@/@api/lottery'
import { dateFormatter } from '@/@core/utils/date'
import { dicPositions } from '@/@core/constants'

const socket = new WebSocket('wss://free.blr2.piesocket?.com/v3/1?api_key=KJ5bZwUrwNtyDHndTz9DanoiQZOmEaUdKkICD0i7&notify_self=1')

const defaultUser = {
  position: 0,
  awards: [],
  external_id: '',
  phone: '',
  email: '',
  status: '',
}

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BNavbarNav,
    BCollapse,
    BButton,
    BTable,
    BAvatar,
    BPopover,
    BDropdown,
    BDropdownItem,
    BImg,
    vSelect,

    VuexyLogo,
    Tombola,
    RaffleWinnerImage,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      expelIcon: require('@/assets/images/icons/log-out.svg'),
      winnerIcon: require('@/assets/images/icons/winner.svg'),
      substituteIcon: require('@/assets/images/icons/substitute.svg'),
      winnerImage: require('@/assets/images/raffle/winner.png'),
    }
  },
  setup() {
    const dicStatus = {
      winner: 'Ganador',
      expelled: 'Expulsado',
      substitute: 'Suplente',
    }
    const tableColumns = ref([
      { label: 'Premio', key: 'position' },
      { label: 'Nombre', key: 'name' },
      { label: 'Rut', key: 'external_id' },
      { label: 'Celular', key: 'phone' },
      { label: 'Correo', key: 'email' },
      // { label: 'Estado', key: 'status' },
      // { label: '', key: 'actions' },
    ])

    const { route } = useRouter()

    const listBallsRef = ref(null)
    const winnerRef = ref(null)
    const tombolaRef = ref(null)
    const type = ref(null)
    const lottery = ref(null)
    const typeList = ref([{ label: 'Live', value: 'live' }])
    const lotteryList = ref([])

    const winnersList = ref([])
    const substitutesList = ref([])

    const currentWinner = ref(null)
    const showModalWinner = ref(false)

    const obfuscate = ref(true)
    const raffleId = ref(null)
    const resultPlay = ref(null)
    const loading = ref(false)
    const raffleDetail = ref(null)
    const awards = ref([])
    const countParticipants = ref(0)
    const dateNow = ref(dateFormatter((new Date()), 'DD MMMM YYYY hh:mm a'))

    function addBallHeader(position) {
      if (!position) return

      const item = position.winner ?? position

      const template = `<div id="ball-${item.id}" class="ball-add">
        <div class="ball ${getBallColor(item.id)} font-${item.id.toString().length}"><span class="ball-spot">${item.id}</span></div>
      </div>`

      listBallsRef.value.insertAdjacentHTML('afterbegin', template)
      setTimeout(() => {
        document.querySelector(`#ball-${item.id}`).classList.add('animate')
      }, 100)
    }

    function transformText(text) {
      if (!obfuscate.value) return text

      const isEmail = text.includes('@')

      const firstPart = isEmail
        ? text.split('@')[0].slice(0, text.split('@')[0].length - 3)
        : text.slice(0, text.length - 3)
      const lastPart = isEmail
        ? `${text.split('@')[0].slice(-3)}@${text.split('@')[1]}`
        : text.slice(-3)

      const obfus = firstPart.split('').map(t => (t.match(/[\w\d]/i) ? '*' : t)).join('')

      return `${obfus}${lastPart}`
    }

    function formatPositions(positions) {
      const list = []

      const getAwards = (awards) => awards.map(a => typeof a === 'string' ? a : a._uid)

      let k = -1
      while (++k < positions.length) {
        const position = positions[k]

        if (position.places) {
          Object.entries(position.places).forEach(([place, value]) => {
            const temp = { position: place }

            if (value.winner) {
              temp.winner = { ...value.winner, awards: getAwards(position.awards), position: place }
            }

            if (value.substitute) {
              temp.substitute = { ...value.substitute, awards: getAwards(position.awards), position: place }
            }

            list.push(temp)
          })
        }
      }

      return list
    }

    async function findWinner(positionRaffle, opt = {}) {
      try {
        const result = await LotteryRequest.play(raffleId.value, {
          expected_position: parseInt(positionRaffle, 10),
          ...opt,
        }, { notify: false })

        const { data: { data: { positions } } } = result

        const listPositions = formatPositions(positions)

        if (listPositions[0]) {
          currentWinner.value = JSON.parse(JSON.stringify(listPositions[0]))

          // FIXME: Trigger
          if (!obfuscate.value) {
            socket?.send(JSON.stringify({
              channel: `channel-${raffleId.value}`,
              event: 'winner',
              message: currentWinner.value,
            }))
          }
        }

        return JSON.parse(JSON.stringify(listPositions[0]))
      } catch (error) {
        if (error.response?.data?.message === 'The expected position exceeds the positions available for winners') {
          toast({
            title: 'Ups! hemos llegado al límite de ganadores',
            icon: 'InfoIcon',
            variant: 'info',
          })
        }
        if (error.response?.data?.message === "Can't define count winners greater than count participants") {
          toast({
            title: 'No se puede definir un número de ganadores mayor que el de participantes',
            icon: 'InfoIcon',
            variant: 'info',
          })
        }

        tombolaRef.value.stop()

        // FIXME: Trigger
        if (!obfuscate.value) {
          socket?.send(JSON.stringify({
            channel: `channel-${raffleId.value}`,
            event: 'stop',
            message: { message: 'stop' },
          }))
        }
        return null
      }
    }

    function setWinnerInTable() {
      const position = currentWinner.value

      if (!position) return

      const tempWinners = JSON.parse(JSON.stringify(winnersList.value))
      const tempSubstitutes = JSON.parse(JSON.stringify(substitutesList.value))

      tempWinners[position.position - 1] = position.winner
      tempSubstitutes[position.position - 1] = position.substitute

      winnersList.value = tempWinners
      substitutesList.value = tempSubstitutes

      addBallHeader(position)

      // FIXME: Trigger
      if (!obfuscate.value) {
        socket?.send(JSON.stringify({
          channel: `channel-${raffleId.value}`,
          event: 'set_winner',
          message: { message: 'set_winner' },
        }))
      }
    }

    function closeAndSetWinner() {
      setWinnerInTable()
      showModalWinner.value = false
    }

    function stop() {
      tombolaRef.value.stop()

      // FIXME: Trigger
      if (!obfuscate.value) {
        socket?.send(JSON.stringify({
          channel: `channel-${raffleId.value}`,
          event: 'stop',
          message: { message: 'stop' },
        }))
      }

      showModalWinner.value = true
    }

    async function play() {
      if (tombolaRef.value.isPlay) return

      tombolaRef.value.play()

      // FIXME: Trigger
      if (!obfuscate.value) {
        socket?.send(JSON.stringify({
          channel: `channel-${raffleId.value}`,
          event: 'play',
          message: { message: 'play' },
        }))
      }

      currentWinner.value = null
      findWinner(winnersList.value.length + 1)
    }

    function alAgua() {
      const position = currentWinner.value ? currentWinner.value.position : winnersList.value.length + 1
      currentWinner.value = null

      tombolaRef.value.play()

      // FIXME: Trigger
      if (!obfuscate.value) {
        socket?.send(JSON.stringify({
          channel: `channel-${raffleId.value}`,
          event: 'drop_water',
          message: { message: 'drop_water' },
        }))
      }

      showModalWinner.value = false

      findWinner(position, { drop_water: true })
    }

    async function otherForPosition(position, typePosition = 'winner') {
      try {
        currentWinner.value = null
        showModalWinner.value = false

        findWinner(position, { drop_water: true, required: typePosition })

        tombolaRef.value.play()

        // FIXME: Trigger
        if (!obfuscate.value) {
          socket?.send(JSON.stringify({
            channel: `channel-${raffleId.value}`,
            event: 'play',
            message: { message: 'play' },
          }))
        }
      } catch (error) {
        console.log({ error })
      }
    }

    async function togglePosition(position) {
      try {
        await LotteryRequest.togglePosition(raffleId.value, { position })

        const pos = position - 1

        const tempWinners = JSON.parse(JSON.stringify(winnersList.value))
        const tempSubstitutes = JSON.parse(JSON.stringify(substitutesList.value))

        const userWinner = tempWinners[pos]
        const userSubstitutes = tempSubstitutes[pos]

        tempWinners[pos] = userSubstitutes
        tempSubstitutes[pos] = userWinner

        winnersList.value = tempWinners
        substitutesList.value = tempSubstitutes

        toast({
          title: 'Cambiado con éxito',
          icon: 'CheckIcon',
          variant: 'success',
        })
      } catch (error) {
        toast({
          title: 'Ups! no logramos cambiarlo a suplente',
          icon: 'InfoIcon',
          variant: 'info',
        })
      }
    }

    // FIXME: Listen socket
    function listenSocket(id) {
      socket.onmessage = function (e) {
        console.log(JSON.parse(e.data))
        const { channel, event, message: data } = JSON.parse(e.data)

        if (channel !== `channel-${id}`) return

        if (event === 'winner') {
          currentWinner.value = data
        }

        if (event === 'drop_water') {
          tombolaRef.value.play()
          currentWinner.value = null
          showModalWinner.value = false
        }

        if (event === 'set_winner') {
          setWinnerInTable()
          showModalWinner.value = false
        }

        if (event === 'play') {
          // tombolaRef.value.stop()
          tombolaRef.value.play()
          currentWinner.value = null
        }

        if (event === 'stop') {
          stop()
        }
      }
    }

    async function findRaffle(id) {
      raffleId.value = route.value.params.id
      loading.value = true

      try {
        // FIXME: Socket by Piehost
        socket.onopen = function (event) {
          console.log(event.data)
        }

        if (obfuscate.value) listenSocket(raffleId.value)
      } catch (error) {
        console.log({ error })
      }

      try {
        const { data: { data: resp } } = await LotteryRequest.publicShow(id)

        const { raffle, awards: awardsList, positions } = resp

        raffleDetail.value = raffle

        dateNow.value = dateFormatter(raffle.schedule_begin, 'DD MMMM YYYY hh:mm a')

        awards.value = awardsList.reduce((obj, award) => {
          obj[award._uid] = award
          return obj
        }, {})

        // Actualizar tablas de ganadores y suplentes
        const listPositions = formatPositions(positions)
        const tempWinners = []
        const tempSubstitutes = []

        listPositions.forEach(position => {
          if (position.winner) tempWinners[position.position - 1] = position.winner
          if (position.substitute) tempSubstitutes[position.position - 1] = position.substitute
        })

        winnersList.value = tempWinners.map((item, i) => (item ?? { ...defaultUser, position: i + 1 }))
        substitutesList.value = tempSubstitutes.map((item, i) => (item ?? { ...defaultUser, position: i + 1 }))

        tempWinners.forEach((position) => {
          addBallHeader(position)
        })

        const count = resp.participants.total
        countParticipants.value = count
      } catch (error) {
        console.log({ error })
      } finally {
        loading.value = false
      }
    }

    async function findRaffles() {
      try {
        const { data: { data } } = await LotteryRequest.select()
        const list = data.map((raffle) => ({ id: raffle.id, label: raffle.title, value: raffle.id }))

        lotteryList.value = list
        lottery.value = list.find(raffle => raffle.id === parseInt(raffleId.value))
      } catch (error) {
        console.log(error)
      }
    }

    function selectRaffle(val) {
      router.replace(`/sorteo/${val.id}`)
      location.reload()
    }

    function downloadRaffle() {
      console.log(raffleId.value)
    }

    watch(showModalWinner, (val) => {
      if (!val) {
        console.log({ showModalWinner: val })
        // tombolaRef.value.restoreBalls()
      }
    })

    onMounted(() => {
      obfuscate.value = !JSON.parse(localStorage.getItem(jwtConfig.storageUserDataKeyName))

      if (!obfuscate.value) {
        findRaffles()
        tableColumns.value = [...tableColumns.value, { label: '', key: 'actions' }]
      }

      if (route.value.params.id) findRaffle(route.value.params.id)
    })

    return {
      obfuscate,
      dicPositions,
      dicStatus,
      tombolaRef,
      type,
      lottery,
      typeList,
      lotteryList,
      showModalWinner,

      play,
      stop,
      currentWinner,
      winnersList,
      substitutesList,
      tableColumns,
      transformText,

      alAgua,
      otherForPosition,
      setWinnerInTable,
      closeAndSetWinner,
      dateNow,
      raffleDetail,
      awards,
      countParticipants,
      resultPlay,
      togglePosition,

      winnerRef,
      listBallsRef,
      getBallColor,
      selectRaffle,
      downloadRaffle,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.brand-logo {
  display: flex;
  justify-content: center;

  > img {
    margin: 0 !important;
  }

  .brand-text {
    font-weight: 600;
    margin-top: 0.5rem !important;
    margin-bottom: 0;
  }
}

.navbar .navbar-collapse .v-select {
  width: 100%;

  @media (min-width: 768px) {
    width: 160px;
  }

  @media (min-width: 992px) {
    width: 260px;
  }
}

.main-section {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

.card-tombola {
  .card-header {
    border-radius: 8px;
    border: 2px solid #12162B;
    background: #333F66;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: auto;

    gap: 10px;
    padding: 10px 16px;
    min-height: 78px;
  }

  .card-body {
    padding: 50px 10px !important;
  }

  &--img {
    border: 14px solid #333F66;
    width: 100%;
  }

  .card-footer {
    border: 0;
  }
}

.table-container {
  height: 280px;
  overflow-y: auto;
  position: relative;

  thead {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
  }

  thead th:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  thead th:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  thead th,
  tbody tr td {
    border: none;
  }

  tbody tr td {
    padding-top: 12px;
    padding-bottom: 12px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  tbody tr td:last-child {
    overflow: visible;
  }

  .btn.dropdown-toggle::after {
    left: 0;
  }

  tbody tr {
    animation-duration: calc(var(--animate-duration) * 0.75);
    animation-name: slideInDown;
  }
}

.popover .popover-body {
  border-radius: 0.358rem;
  padding: 1rem;
}

@media (min-width: 768px) {
  .no-substitute {
    height: calc(100% - 1.5rem);

    > .table-container {
      height: 100%;
    }
  }
}

#confetti {
  z-index: 2000;
  pointer-events: none;
  position: relative;
}

.ball-add {
  width: 0;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;

  &.animate {
    width: 54px;
    transition: width 250ms ease-in-out;
  }
}

.dark-layout {
  .navbar-light .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
  }
}
</style>
