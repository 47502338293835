<template>
  <div
    ref="canvaRef"
    :class="{ 'raffle-winner': true, 'raffle-winner__visible': visible }"
    @click="close"
  >
    <div class="raffle-winner--content">
      <div
        ref="ballRef"
        :class="['ball', 'active', 'position-absolute', getBallColor(id), `font-${id.toString().length}`]"
      >
        <span class="ball-spot">{{ id ? id : '' }}</span>
      </div>
      <div class="raffle-winner--wrapper-cup">
        <div
          ref="cupRef"
          class="raffle-winner--cup"
          :style="`background-image: url(${winnerImage})`"
        />
        <div
          ref="splashRef"
          class="raffle-winner--splash"
          :style="`background-image: url(${spriteSplash})`"
        />
      </div>

      <h2
        :class="{
          'text-white my-2': true,
          'fade': hideDetails
        }"
      >
        {{ label ? label : '' }}
      </h2>

      <div v-if="!obfuscate" :class="`w-100 d-flex justify-content-center align-items-center flex-column gap-2 ${hideDetails ? 'fade': ''}`">
        <b-button variant="primary" block>
          Seleccionado!!
        </b-button>

        <b-button variant="secondary" @click.stop="playSplash">
          Al agua
        </b-button>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable global-require */
import { BButton } from 'bootstrap-vue'
import { onMounted, ref } from '@vue/composition-api'
import anime from 'animejs'
import { getBallColor } from '@/@core/utils/ballColor'

require('@core/libs/confetti/tsparticles.confetti.bundle.min')

export default {
  components: {
    BButton,
  },
  props: {
    visible: Boolean,
    obfuscate: Boolean,
    id: {
      type: [String, Number],
      default: '',
    },
    label: String,
  },
  emits: ['onWater', 'onClose'],
  data() {
    return {
      winnerImage: require('@/assets/images/raffle/winner.png'),
      spriteSplash: require('@/assets/images/raffle/sprite_splash.svg'),
    }
  },
  watch: {
    visible(val) {
      if (val) {
        document.body.style.pointerEvents = 'none'
        this.playBall()
      } else {
        this.$refs.splashRef.classList.remove('animate')
        this.$refs.canvaRef.classList.remove('raffle-winner__visible')
        this.$refs.cupRef.style.opacity = 0
        document.body.style.pointerEvents = 'all'
      }
    },
  },
  setup(_, { emit }) {
    const canvaRef = ref(null)
    const ballRef = ref(null)
    const cupRef = ref(null)
    const splashRef = ref(null)

    const cupAnimate = ref(null)
    const ballAnimate = ref(null)
    const tadaAnimate = ref(null)
    const hideDetails = ref(false)

    function close() {
      emit('onClose', false)
      hideDetails.value = false
    }

    function showConfetti() {
      const end = Date.now() + 3 * 1000

      // go Buckeyes!
      const colors = ['#bb0000', '#ffffff']

      function randomInRange(min, max) {
        return Math.random() * (max - min) + min
      }

      const interval = setInterval(() => {
        confetti({
          angle: randomInRange(55, 125),
          spread: randomInRange(50, 70),
          particleCount: randomInRange(50, 100),
          origin: { y: 0.6 },
        })
      }, 600)

      function frame() {
        confetti({
          particleCount: 2,
          angle: 60,
          spread: 55,
          origin: { x: 0 },
          colors,
        })

        confetti({
          particleCount: 2,
          angle: 120,
          spread: 55,
          origin: { x: 1 },
          colors,
        })

        if (Date.now() < end) {
          requestAnimationFrame(frame)
        } else {
          clearInterval(interval)
        }
      }

      frame()
    }

    function playBall() {
      ballAnimate.value.play()
    }

    function playTada() {
      tadaAnimate.value.play()
      showConfetti()
    }

    function playSplash() {
      cupAnimate.value.play()
      hideDetails.value = true
      splashRef.value.classList.add('animate')
    }

    onMounted(() => {
      ballAnimate.value = anime({
        targets: ballRef.value,
        translateY: [200, -80],
        scale: {
          value: [0.4, 3],
          delay: 100,
        },
        duration: 700,
        easing: 'easeOutBack',
        autoplay: false,
        complete: () => {
          setTimeout(() => {
            playTada()
          }, 600)
        },
      })

      tadaAnimate.value = anime({
        targets: cupRef.value,
        opacity: [
          { value: 0 },
          { value: 1 },
        ],
        scale: [
          { value: 0.9 },
          { value: 1 },
        ],
        rotate: [
          { value: 0, duration: 150 },
          { value: -3, duration: 150 },
          { value: 3, duration: 150 },
          { value: -3, duration: 150 },
          { value: 3, duration: 150 },
          { value: -3, duration: 150 },
          { value: 3, duration: 150 },
          { value: -3, duration: 150 },
          { value: 3, duration: 150 },
          { value: -3, duration: 150 },
          { value: 3, duration: 150 },
          { value: 0, duration: 150 },
        ],
        duration: 600,
        easing: 'easeInOutExpo',
        autoplay: false,
        complete: () => {
          ballRef.value.style.transform = 'scale(0)'
          document.body.style.pointerEvents = 'all'
        },
      })

      cupAnimate.value = anime({
        targets: cupRef.value,
        translateY: [
          { value: -2000, duration: 1000, easing: 'easeInOutExpo' },
          { value: 30, duration: 500, delay: 0 },
        ],
        scale: [
          { value: 0.1, duration: 500, easing: 'easeInOutExpo' },
          { value: 0.1, duration: 500 },
        ],
        opacity: [
          { value: 1 },
          { value: 0, duration: 50, delay: 1100 },
        ],
        autoplay: false,
      })

      splashRef.value.addEventListener('animationend', () => {
        splashRef.value.classList.remove('animate')
        canvaRef.value.classList.remove('raffle-winner__visible')
        emit('onWater')
        // close()
        hideDetails.value = false
      })
    })

    return {
      canvaRef,
      ballRef,
      cupRef,
      splashRef,
      playBall,
      playTada,
      playSplash,
      showConfetti,
      close,
      hideDetails,
      getBallColor,
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/animate/tada.scss';

.raffle-winner {
  // pointer-events: none;
  display: none;
  background: #22292fb0;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100vw;
  height: 100vh;

  align-items: center;
  justify-content: center;
  flex-direction: column;

  &--content {
    text-align: center;
    width: 320px;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }

  &__visible {
    display: flex;

    .raffle-winner--content {
      opacity: 1;
    }
  }

  &--wrapper-cup {
    width: 100%;
    aspect-ratio: 1;
    position: relative;
  }

  &--cup {
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    opacity: 0;
    aspect-ratio: 1;
  }

  &--splash {
    pointer-events: none;
    width: 300%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    aspect-ratio: 16 / 8;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 0 100%;
    opacity: 0;

    &.animate {
      animation: splashSprite 1.5s steps(23) 1.05s;
    }
  }

  .ball {
    top: calc(50% - 20px);
    left: calc(50% - 25px);
  }
}

@keyframes splashSprite {
  from {
    opacity: 1;
    background-position: 0 0%;
  }
  to {
    opacity: 1;
    background-position: 0 100%;
  }
}
</style>
