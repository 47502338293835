import http from './http'
import Resource from './resource'
// import FakeResource from './fake-resource'

const path = 'admin/tombola/raffles'

class Lottery extends Resource {
  constructor(_http) {
    super(path, _http)
  }

  select(opt = {}) {
    return this.http.get(`api/${path}/select/list`, opt)
  }

  participants(id) {
    return this.http.get(`api/${path}/${id}/participants`)
  }

  play(id, opt = {}, conf = {}) {
    return this.http.post(`api/${path}/${id}/play`, opt, conf)
  }

  publicShow(id, opt = {}) {
    return this.http.get(`api/tombola/raffles/${id}`, opt)
  }

  togglePosition(id, opt = {}) {
    return this.http.put(`api/${path}/${id}/shift`, opt)
  }
}

const LotteryRequest = new Lottery(http)

export default LotteryRequest
